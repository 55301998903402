import { defineStore } from "pinia";
import type { IAlert } from "~/types/generalData.interface";

export const useUXStore = defineStore("ux", {
  state: () => ({
    contentChanged: false,
    enableExport: false,
    formStep: "upload",
    isLoaded: false,
    isLoading: false,
    loaderMessage: "",
    showAlert: false,
    alertContent: {} as IAlert,
    showMissionContextForm: false,
    disabledContinue: false,
    fetchingTasks: true,
    hasProcessing: true,
    showingMoreCFF: true,
  }),
  actions: {
    setFormStep(value: string) {
      this.formStep = value;
    },
    setDataLoaded(value: boolean) {
      this.isLoaded = value;
    },
    setDataLoading(value: boolean) {
      this.isLoading = value;
    },
    setContentChanged(value: boolean) {
      this.contentChanged = value;
    },
    setExportEnable(value: boolean) {
      this.enableExport = value;
    },
    setAlert(value: boolean, content: IAlert) {
      this.showAlert = value;
      this.alertContent = content;
    },
    handleFormSubmitUX() {
      this.isLoading = true;
    },
    setMissionContextForm(value: boolean) {
      this.showMissionContextForm = value;
    },
    setDisabledContinue(value: boolean) {
      this.disabledContinue = value;
    },
    setFetchingTasks(value: boolean) {
      this.showingMoreCFF = value;
    },
    setProcessing(value: boolean){
      this.hasProcessing = value;
    }
  },
});
